import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { SideMenu } from "@fastly/consistently"
import RecentlyViewed from "~components/RecentlyViewed/RecentlyViewed"

import NavGuideData from "~data/nav_products.yml"

import { Yaml2Menu } from "./side-menu"

export const SideMenuProducts = () => {
  const data = useStaticQuery(graphql`
    query HeaderQueryProduct {
      primarys: allMdx(filter: { fileAbsolutePath: { regex: "//_products_primary/" } }) {
        nodes {
          frontmatter {
            cat_id
            section_name
          }
        }
      }
      secondarys: allMdx(filter: { fileAbsolutePath: { regex: "//_products_secondary/" } }) {
        nodes {
          frontmatter {
            crumb
            name
          }
        }
      }
      guides: allMdx(
        filter: { fileAbsolutePath: { regex: "//_products//" }, frontmatter: { published: { ne: false } } }
        sort: { fields: slug }
      ) {
        group(field: frontmatter___secondary_nav) {
          fieldValue
          nodes {
            fileAbsolutePath
            slug
            frontmatter {
              header
              primary_nav
              secondary_nav
              unlisted
            }
          }
        }
      }
      orphans: allMdx(
        filter: { fileAbsolutePath: { regex: "//_products//" }, frontmatter: { published: { ne: false } } }
        sort: { fields: slug }
      ) {
        group(field: frontmatter___primary_nav) {
          fieldValue
          nodes {
            fileAbsolutePath
            slug
            frontmatter {
              header
              primary_nav
              secondary_nav
              unlisted
            }
          }
        }
      }
    }
  `)

  const slug_collection = "_products/"
  const link_prefix = "/products/"

  const menu = Yaml2Menu(data, NavGuideData, slug_collection, link_prefix)

  return (
    <SideMenu>
      {menu}

      <RecentlyViewed />
    </SideMenu>
  )
}
