import React from "react"
import FrontmatterPageContext from "~context/FakePageContext"

// https://storybook.staging.secretcdn.net/?path=/docs/components-sidemenu--example#sidemenu
import { L1Item, L1Header } from "@fastly/consistently"
import { L2Menu, L2Item, L2Header } from "@fastly/consistently"
import { L3Menu, L3Item } from "@fastly/consistently"

import { SideMenuGuidesEN } from "./side-menu-guides-en"
import { SideMenuGuidesJA } from "./side-menu-guides-ja"
import { SideMenuProducts } from "./side-menu-products"
import { SideMenuFundamentalsEN } from "./side-menu-fundamentals-en"
import { SideMenuFundamentalsJA } from "./side-menu-fundamentals-ja"

export const SideMenuSwitcher = () => {
  const frontmatter = React.useContext(FrontmatterPageContext)

  switch (frontmatter.section_safe) {
    case "guides":
    case "waf_rule_sets":
      switch (frontmatter.lang || "en") {
        default:
        case "en":
          return <SideMenuGuidesEN />
        case "ja":
          return <SideMenuGuidesJA />
      }
    case "products":
      return <SideMenuProducts />
    case "fundamentals":
      switch (frontmatter.lang || "en") {
        default:
        case "en":
          return <SideMenuFundamentalsEN />
        case "ja":
          return <SideMenuFundamentalsJA />
      }
    case "canary":
      return <>🐤</>
    case "none":
      return ""
    default:
      return `--ELSE (${frontmatter.section_safe})--`
  }
}

export function Yaml2Menu(data, navYaml, slug_collection, link_prefix) {
  const frontmatter = React.useContext(FrontmatterPageContext)

  const openedMenu = frontmatter.mySecondary?.frontmatter?.crumb || ""
  return navYaml.map((toplevel) => {
    const L1meta = data.primarys.nodes.filter((d) => d.frontmatter["cat_id"] === toplevel.crumb)[0].frontmatter
    const myOrphans =
      data.orphans.group
        .filter((groupset) => groupset.fieldValue === toplevel.crumb)[0]
        ?.nodes.filter((n) => n.frontmatter.unlisted !== true) || []

    let showPrimary = 0

    let secondaries = null
    if (toplevel["secondary-nav"]?.length) {
      showPrimary += 1
      secondaries = (
        <L2Menu type="multiple" defaultValue={[openedMenu]} key={toplevel.crumb}>
          {toplevel["secondary-nav"].map((secondary) => (
            <L2Item value={secondary.crumb} key={secondary.crumb}>
              <L2Header>
                {data.secondarys.nodes.find((n) => n.frontmatter.crumb === secondary.crumb).frontmatter.name}
              </L2Header>
              <L3Menu>
                {data.guides.group
                  .find((n) => n.fieldValue === secondary.crumb)
                  .nodes.filter((n) => n.frontmatter.unlisted !== true)
                  .sort((a, b) => (a.frontmatter.header.toUpperCase() > b.frontmatter.header.toUpperCase() ? 1 : -1))
                  .map((third) => {
                    const href = `${link_prefix}${third.slug.replace(slug_collection, "")}`
                    const currentPage = `${link_prefix}${frontmatter.fileslug}`
                    return (
                      <L3Item href={href} aria-current={href === currentPage && "page"} key={third.slug}>
                        {third.frontmatter.header}
                      </L3Item>
                    )
                  })}
              </L3Menu>
            </L2Item>
          ))}
        </L2Menu>
      )
    }

    let orphansL2 = null
    if (myOrphans.length) {
      showPrimary += 2
      orphansL2 = (
        <L2Menu type="multiple">
          {myOrphans.map((thisOrphan) => {
            const href = `${link_prefix}${thisOrphan.slug.replace(slug_collection, "")}`
            const currentPage = `${link_prefix}${frontmatter.fileslug}`
            return (
              <L3Item href={href} aria-current={href === currentPage && "page"}>
                {thisOrphan.frontmatter.header}
              </L3Item>
            )
          })}
        </L2Menu>
      )
    }

    return (
      <L1Item>
        {showPrimary > 0 ? (
          <>
            <L1Header href={`${link_prefix}${toplevel["crumb"]}`}>{L1meta.section_name}</L1Header>
            {secondaries}
            {orphansL2}
          </>
        ) : (
          ""
        )}
      </L1Item>
    )
  })
}
