import React, { useState, useEffect, useContext } from "react"

import FrontmatterPageContext from "~context/FakePageContext"
import { Trash } from "@fastly/consistently-icons"
import { container, header, list, listItem, iconButton, trashIcon } from "./RecentlyViewed.css"

const RECENTLY_VIEWED_KEY = "recentlyViewed"
const RECENTLY_VIEWED_LIMIT = 4

const RecentlyViewed = () => {
  const [items, setItems] = useState([])
  const frontmatter = useContext(FrontmatterPageContext)

  const clearStorage = () => {
    localStorage.removeItem(RECENTLY_VIEWED_KEY)
    setItems([])
  }

  // get the thing from localStorage (note: order of the useEffect matters)
  useEffect(() => {
    try {
      const recentlyViewed = JSON.parse(localStorage.getItem(RECENTLY_VIEWED_KEY))
      Array.isArray(recentlyViewed) && setItems(recentlyViewed.reverse().slice(0, RECENTLY_VIEWED_LIMIT))
    } catch (e) {
      localStorage.removeItem(RECENTLY_VIEWED_KEY)
    }
  }, [])

  // set the thing in localStorage
  useEffect(() => {
    if (!frontmatter.header) return

    try {
      // if localStorage is null, this will create a Map with size 0
      const recentlyViewed = new Map(JSON.parse(localStorage.getItem(RECENTLY_VIEWED_KEY)))
      if (frontmatter?.header) {
        recentlyViewed.set(window.location.pathname, frontmatter.header)
        localStorage.setItem(RECENTLY_VIEWED_KEY, JSON.stringify([...recentlyViewed]))
      }
    } catch (e) {
      // Something failed, likely mangled JSON
      // send to sentry?
      // delete localStorage key?
      localStorage.removeItem(RECENTLY_VIEWED_KEY)
      console.error(e)
    }
  }, [])

  return (
    <>
      {items.length > 0 ? (
        <div className={container}>
          <div className={header}>
            Recently viewed
            <button className={iconButton} onClick={clearStorage}>
              <Trash className={trashIcon} />
            </button>
          </div>
          <ol className={list}>
            {items.map((item) => (
              <li key={item[0]} className={listItem}>
                <a href={item[0]}>{item[1]}</a>
              </li>
            ))}
          </ol>
        </div>
      ) : null}
    </>
  )
}

export default RecentlyViewed
