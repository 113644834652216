import React from "react"
import FrontmatterPageContext from "~context/FakePageContext"

import { mainArea, main } from "~vanilla/layout-default.css"
import { sideMenu } from "~components/SideMenu/side-menu.css"

import LayoutCore from "./layout-core"

import { SideMenuSwitcher } from "~components/SideMenu/side-menu"

const WrapLayoutDefault = (props) => {
  const { frontmatter = {}, children } = props

  frontmatter["section_safe"] = frontmatter["section"] || "none"

  return (<>
    <FrontmatterPageContext.Provider value={frontmatter}>
      <LayoutCore>
        <div className={`${mainArea} grid-main`}>
          <div
            className={`${sideMenu} main-nav section-${frontmatter.section_safe}`}
            role="navigation"
            aria-label="Navigation between documents"
            data-swiftype-index="false"
          >
            <SideMenuSwitcher />
          </div>

          <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
            {children}
          </main>
        </div>
      </LayoutCore>
    </FrontmatterPageContext.Provider>
    </>
  )
}

export default WrapLayoutDefault
