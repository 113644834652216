import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { SideMenu } from "@fastly/consistently"
import RecentlyViewed from "~components/RecentlyViewed/RecentlyViewed"

import NavData from "~data/nav_fundamentals.yml"

import { Yaml2Menu } from "./side-menu"

export const SideMenuFundamentalsJA = () => {
  const data = useStaticQuery(graphql`
    query HeaderQueryFundamentalsJA {
      primarys: allMdx(filter: { fileAbsolutePath: { regex: "//_ja_fundamentals_primary/" } }) {
        nodes {
          frontmatter {
            cat_id
            section_name
          }
        }
      }
      secondarys: allMdx(filter: { fileAbsolutePath: { regex: "//_ja_fundamentals_secondary/" } }) {
        nodes {
          frontmatter {
            crumb
            name
          }
        }
      }
      guides: allMdx(
        filter: { fileAbsolutePath: { regex: "//_ja_fundamentals//" }, frontmatter: { published: { ne: false } } }
        sort: { fields: slug }
      ) {
        group(field: frontmatter___secondary_nav) {
          fieldValue
          nodes {
            fileAbsolutePath
            slug
            frontmatter {
              header
              primary_nav
              secondary_nav
              unlisted
            }
          }
        }
      }
      orphans: allMdx(
        filter: { fileAbsolutePath: { regex: "//_ja_fundamentals//" }, frontmatter: { published: { ne: false } } }
        sort: { fields: slug }
      ) {
        group(field: frontmatter___primary_nav) {
          fieldValue
          nodes {
            fileAbsolutePath
            slug
            frontmatter {
              header
              primary_nav
              secondary_nav
              unlisted
            }
          }
        }
      }
    }
  `)

  const slug_collection = "_ja_fundamentals/"
  const link_prefix = "/ja/fundamentals/"

  const menu = Yaml2Menu(data, NavData, slug_collection, link_prefix)

  return (
    <SideMenu>
      {menu} <RecentlyViewed />
    </SideMenu>
  )
}
