import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "layout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#layout",
        "aria-label": "layout permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Layout`}</h2>
    <ul>
      <li parentName="ul">{`Same content, different layout`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/test-full"
            }}>{`default_full`}</a>{` - without sidebar`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/test-default"
            }}>{`default`}</a>{` - with sidebar`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/test-article"
            }}>{`guide-article`}</a>{` - with sidebar + 2x3 content bits`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "styling",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#styling",
        "aria-label": "styling permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Styling`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/canary/lists"
        }}>{`lists`}</a>{` - tests to make sure that all UL and OL lists play nicely with images and spacing`}</li>
      <li parentName="ul">{`last-date`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/lastdate"
            }}>{`normal`}</a>{` - test to make sure the last-updated date renders as expected`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/lastdate-off"
            }}>{`suppressed`}</a>{` - test to make sure that the date suppression frontmatter works`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/lastdate-missing"
            }}>{`missing`}</a>{` - test to make sure the lastUpdated include properly works when there is no date data`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/canary/tail-icons"
        }}>{`tail-icons`}</a>{` - tests for various CSS appended "after icons," mostly based on URL rules`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/canary/colors"
        }}>{`colors!`}</a>{` - did the colors module load correctly?`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/canary/floated-image"
        }}>{`floated-image`}</a>{` - test page to make sure that the CSS hack for "side floated" images works`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/canary/tables"
        }}>{`tables!`}</a>{` - test page for styling tables`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/tables-user-roles"
            }}>{`userroles table styling`}</a>{` - a test of the CSS for the user roles table for one very bespoke page`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features",
        "aria-label": "features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Code`}</p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/code-features"
            }}>{`codeblocks features`}</a>{` - tests to ensure that codeblock features (numbered, unnumbered, and labels) style correctly`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/code-languages"
            }}>{`codeblocks languages`}</a>{` - test the languages`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/canary/js-anchors"
          }}>{`header anchors`}</a>{` - headers should get "section anchor" links for bookmarking and sharing`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Table of contents aka "On This Page"`}</p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/toc-normal"
            }}>{`toc - normal`}</a>{` - the "On this page" TOC`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/toc-headers-rich"
            }}>{`toc - headers with stuff`}</a>{` - testing how the OTP handles headers with extras`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/toc-min"
            }}>{`toc - minimumHeaders`}</a>{` - testing the that OTP honors our rules for a minimum number of headers before showing`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "tag",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tag",
        "aria-label": "tag permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tag`}</h2>
    <p>{`Custom `}<del parentName="p">{`liquid`}</del>{` tags (we got 'em, so we test 'em ;) ):`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/canary/awesome"
        }}><inlineCode parentName="a">{`<Awesome>`}</inlineCode></a>{` - test the usage of the FontAwesome wrapper`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/awesome-usage"
            }}>{`awesome usage`}</a>{` - a central listing of all the FA icons used around the site`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/canary/alerts"
        }}><inlineCode parentName="a">{`<Alert>`}</inlineCode></a>{` - (a.k.a., 'callouts') tests the features of the Alert wrapper`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/alerts-ja"
            }}>{`ja`}</a>{` - tests that (default) Alert headers auto localize`}</li>
        </ul>
      </li>
      <li parentName="ul">{`i18n test pages, testing features of `}<inlineCode parentName="li">{`<T>`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/i18n"
            }}>{`en`}</a>{` - some `}<inlineCode parentName="li">{`<T>`}</inlineCode>{` tests`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/canary/i18n-ja"
            }}>{`ja`}</a>{` - same test as above 👆, but with `}<inlineCode parentName="li">{`lang: ja`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/canary/dry"
        }}><inlineCode parentName="a">{`<DRY>`}</inlineCode></a>{` - tests the i18n tag, which isn't translated`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/canary/lipsum"
        }}><inlineCode parentName="a">{`<LoremIpsum>`}</inlineCode></a>{` - tests for the `}<inlineCode parentName="li">{`LoremIpsum`}</inlineCode>{` tag (also seen on many other test pages)`}</li>
      <li parentName="ul"><del parentName="li"><em parentName="del">{`street date`}</em></del>{
          /* [street date](/canary/street) */
        }{` - tests `}<inlineCode parentName="li">{`hide_until`}</inlineCode>{` & `}<inlineCode parentName="li">{`hide_after`}</inlineCode>{`, (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Street_date"
        }}>{`read more`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "misc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#misc",
        "aria-label": "misc permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Misc`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/canary/env"
        }}>{`env`}</a>{` - environment related things`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/canary/vale"
        }}>{`vale`}</a>{` - vale test page (known "bad" things)`}</li>
      <li parentName="ul"><del parentName="li"><em parentName="del">{`easter egg`}</em></del>{
          /* [easter egg](/canary/easter-egg) */
        }{` - tests the date logic used in the header nav easter egg inserter`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      